import * as React from 'react'
import Layout from '/src/components/layout'

import Anvandarvillkor from '/src/components/villkor'

const VillkorPage = () => {
	return (
		<Layout pageTitle="Användarvillkor">

            <Anvandarvillkor />

		</Layout>
	)
}

export default VillkorPage